@import "variables";
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@700&display=swap');

//@font-face {
//  font-family: 'GT Walsheim';
//  src: url('../fonts/GT-Walsheim-Regular.ttf');
//  font-weight: 400;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: 'GT Walsheim';
//  src: url('../fonts/GT-Walsheim-Bold.ttf');
//  font-weight: 700;
//  font-style: normal;
//}
//
//h1.purpletext, h2.purpletext {font-weight: bold;}
//
//* {
//  font-family: "GT Walsheim";
//}

ion-title.title-large {
  --color: white;
}

ion-title {
  --background: transparent;
}

ion-content {
  --background: transparent;
}

ion-list-header {
  font-size: 20px;
  color: rebeccapurple;
}

.inputfield {
  color: rebeccapurple;
  width: 250px;
  height: 35px;
  background: white;
  box-shadow: black;

}

ion-item {
  --highlight-height: 0px;
}


ion-tab-bar {
  --border: none;
}

ion-title {
  color: black;
}

ion-back-button {
  --color: var(--ion-color-tertiary);
}

ion-toolbar {
  --background: none;

  ion-title:not(.nametitle, .headerLeft) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

ion-toolbar:last-of-type {
  --border-width: 0 0 1px 0;
  --border-color: #eee;
}

ion-segment {
  opacity: 0.9;

  ion-segment-button {
    --indicator-color: white;
  }
}

.searchbar {
  --background: white;
  --color: var(--ion-color-tertiary);
  --icon-color: var(--ion-color-tertiary);
  --placeholder-color: var(--ion-color-tertiary);
  --box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);

  &.small {
    @media only screen and (min-width: 600px) {
      width: 200px;
    }
  }
}

.float-right {
  float: right;
}

.control {
  margin-top: 10px;
  --border-style: none;
}

.segment-control {
  @media only screen and (min-width: 600px) {
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin-left: 0;
    background-color: white;
    font-weight: bolder;
    color: var(--ion-color-tertiary);
  }

  @media only screen and (max-width: 600px) {
    margin: 5px 0;
    width: 100%;
  }
}

ion-segment-button {
  @media only screen and (min-width: 600px) {
    --background: transparent;
    --indicator-box-shadow: none;
    --color-checked: var(--ion-color-primary);
    --border-style: none;
    flex-basis: auto;
    font-size: 16px;
    --padding-top: 5px;
    --padding-bottom: 5px;
  }
}

.icon-white-button {
  --background: white;
  --color: var(--ion-color-tertiary);
  --box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
  height: 42px;
}

.blacktext {
  color: black;
}

.picture {
  width: 60px;
  height: 60px;
  margin: 10px 10px 10px 0;
}
.text {
  color: black;
  padding-left: 0;

  white-space: break-spaces;

  &.small {
    font-size: 18px;
  }

  &.smaller {
    font-size: 16px;
  }
}

ion-grid, ion-row {
  @media screen and (min-width: 600px) {
    padding-left: 0;
  }
}

.section-grid-row {
  margin-bottom: 10px;
}

.itemdivider {
  --background: transparent;
  --color: var(--ion-color-tertiary);
  font-size: 14px;
  @media screen and (min-width: 600px) {
    padding-left: 0;
  }
}

.listcarditem {
  --background: white;
  margin: 10px 15px 0 15px;
}

.itemlabel {
  margin-block-start: 0.15em;
  margin-block-end: 0.15em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-right: 5px;

  display: inline-block;
  margin-top: 0.125em;
  padding: 4px;
  font-size: 10px;

  border: 1px solid rebeccapurple;
  border-radius: 10px;
  color: rebeccapurple;
}

.labelbullet {
  margin-block-end: 0.15em;
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
  padding: 3.5px;

  border: 1px solid;
  border-radius: 5px;
}

.spinner {
  @media screen and (max-width: 600px) {
    margin-left: 46%;
    margin-top: 30%;
  }
  @media screen and (min-width: 600px) {
    margin-left: 50%;
    margin-top: 30%;
  }
}

.boldlabel {
  font-weight: bold;
  width: 50%;
}

.item-lines-full {
  --border-color: #ccc;
}

.list-md-lines-full .item {
  --border-color: #ccc;
}

:host {
  .list-header-lines-full {
    --border-color: #ccc
  }
}

ion-list-header {
  --border-color: #ccc
}

.list-ios-lines-full .item {
  --border-color: #ccc
}

.submitButton, .largeButton {
  padding-left: calc(var(--padding-start) + var(--ion-safe-area-left, 0px));
  padding-right: var(--padding-end);
  width: 100%;
  white-space: normal !important;
}

ion-header {
  background: #fff;
}

.wrap {
  white-space: normal !important;
}

.item-options-ios {
  border-bottom-color: #ccc;
}

.item-options-md {
  border-bottom-color: #ccc;
  border-bottom-width: 0.55px;
}

.list-md {
  padding: 0 0 0 0;
}

ion-toggle {
  --background: white;
  --background-checked: rebeccapurple;

  --handle-background: rebeccapurple;
  --handle-background-checked: white;
}

.picker-above-highlight.sc-ion-picker-ios {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, var(--background, var(--ion-background-color, #fff))), to(rgba(#fff, 0.8)));
  background: linear-gradient(to bottom, var(--background, var(--ion-background-color, #fff)) 20%, rgba(#fff, 0.8) 100%);
}

.picker-below-highlight.sc-ion-picker-ios {
  background: -webkit-gradient(linear, left bottom, left top, color-stop(30%, var(--background, var(--ion-background-color, #fff))), to(rgba(#fff, 0.8)));
  background: linear-gradient(to top, var(--background, var(--ion-background-color, #fff)) 30%, rgba(#fff, 0.8) 100%);
}

.ion-page {
  background-image: url("/assets/background.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f2f2f2;
}

.badge {
  background-color: #ef473a;
  color: #fff;
  z-index: 1;
  display: inline-block;
  min-width: 20px;
  border-radius: 10px;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-left: -10px;
  margin-top: -10px;
  padding: 2px;
}

.badgeTab {
  position: absolute;
  background-color: #ef473a;
  color: #fff;
  z-index: 1;
  display: inline-block;
  min-width: 20px;
  border-radius: 10px;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;
  margin-top: -20px;
  margin-left: 30px;
  line-height: 16px;
  padding: 2px;
}

.f-c-g {
  color: #666666;
}

.f-w-n {
  font-weight: normal;
}

.f-s-s {
  font-size: small;
}

.crop-content {
  img {
    height: auto;
    max-width: calc(100% - var(--padding-end));
  }

  table {
    max-width: calc(100% - var(--padding-end));
    word-wrap: break-word;

    img {
      max-width: calc(100% - var(--padding-end) - var(--padding-end));
    }
  }
}

.is-prev {
  background: #e9e9ed;
  color: rebeccapurple;
}

.is-next {
  background: #e9e9ed;
  color: rebeccapurple;
}

.mainHeader, .infoFooter {
  display: none;
}

.link {
  cursor: pointer;
}

.hide-if-none-desktop {
  display: none;
}

.show-on-tablet-desktop {
  display: unset;
  @media screen and (max-width: 750px) {
    display: none;
  }
}

.headerLeft {
  margin-left: 20px;
  padding: 0;
  text-align: start;
}

@media screen and (min-width: 600px) {
  .plt-desktop {
    .mainHeader {
      background: white;
      height: 80px;
      position: absolute;
      display: flex;
      z-index: 100;
      width: 100%;
      padding: 0 calc((100% - 1200px) / 2);
      justify-content: flex-start;
      align-items: center;

      .label {
        margin: 0 20px;
        color: rebeccapurple;
        font-size: 20px;
        width: 475px;
        overflow: hidden;
        word-wrap: break-word;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.2;
        max-height: calc(3 * 1em * 1.2);
      }

      img {
        margin-left: 12px;
        @media screen and (min-width: 1200px) {
          margin-left: 0px;
        }
        max-height: 55px;
      }
    }

    .mainTabs {
      top: 80px;
      bottom: 60px;
      overflow: visible;

      & > ion-tabs {
        flex-direction: column-reverse !important;
      }
    }

    #root > .ion-page > ion-router-outlet {

      & > .ion-page {
        & > ion-content::part(scroll) {
          margin-top: 0px;
          height: auto !important;
          padding: 0 calc((100% - 1200px) / 2);
          overflow: visible !important;
          display: block !important;
          position: unset !important;
        }
      }

      & > .ion-page ion-header {
        margin-top: 20px;

        ion-title {
          font-size: 22px;
        }
      }

      .footerMenu {

        @media screen and (min-width: 1200px) {
          margin-left: calc((100% - 1200px) / 2 + 620px);
          width: 620px;
          position: absolute;
          top: -65px;
        }

        ion-tab-button::part(native) {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          margin: 0 10px;
        }

        .label {
          color: rebeccapurple;
          display: flex;
          font-size: 14px;
          margin: 0 10px;
        }

        .activeTab .label {
          color: #F83038;
        }
      }

      .infoFooter {
        background: white;
        height: 60px;
        position: absolute;
        display: flex;
        z-index: 101;
        width: 100%;
        padding: 0 calc((100% - 1200px) / 2);
        justify-content: flex-start;
        bottom: 0;
        align-items: center;

        a {
          color: var(--ion-color-tertiary);
          text-decoration: none;
          border-style: none solid none none;
          padding: 0 20px;
        }

        .end {
          border-style: none;
        }
      }


      & > .ion-page {
        ion-header {
          background: none;

          & ion-toolbar {
            padding: 0 calc((100% - 1200px) / 2);
          }
        }

        & > ion-tabs .tabs-inner .ion-page {
          box-shadow: inset 0px 10px 5px -5px rgba(44, 25, 69, 0.1);
          display: block;
          position: unset;
          flex: unset;
          contain: unset;
          overflow: auto;
          height: 100%;

          & > ion-content {
            height: calc(100% - 80px - 60px);
          }

          & > ion-content::part(scroll) {
            margin-top: 0px;
            height: auto !important;
            padding: 0 calc((100% - 1200px) / 2);
            overflow: visible !important;
            display: block !important;
            position: unset !important;
          }

          & > ion-content::part(background) {
            z-index: -1;
          }
        }
      }
    }

    .hide-if-none-desktop {
      display: unset;
    }
  }
}

html:not(.plt-cordova) {
  .hide-if-none-cordova {
    display: none !important;
  }
}

.hide-if-desktop {
  display: unset;
  @media screen and (min-width: 600px) {
    display: none;
  }
}